import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {FaIcon} from 'client/js/util/layout_utils';
import {GenericReactForm, StringInput, SubmitButton} from 'client/js/util/form_utils';
import {PaymentModalContent} from './payment';

class AddFundsButton extends React.Component {
  state = {modal_open: false, submitted: false}

  handleClick = (e) => {
    e.preventDefault();
    this.setState({modal_open: !this.state.modal_open});
  }

  handleReturn = (e) => {
    e.preventDefault();
    this.setState({submitted: !this.state.payment_data});
  }

  handleSuccess = () => {
    this.setState({submitted: false, paid: true, modal_open: false});
  }

  handleForm = (action, e, data) => {
    if(action != 'form:success')
      return;

    this.setState({payment_data: data.object, submitted: true});
  }

  render() {
    return <div className={this.props.wrapperClass}>
      <button className={this.props.btnClass} onClick={this.handleClick} disabled={!!this.state.modal_open || this.state.paid}>{this.state.paid ? 'Guthaben erfolgreich aufgeladen!' : [<FaIcon key={0} name="euro" />, " Guthaben aufladen"]}</button>

      <Modal isOpen={this.state.modal_open} className="modal fade in show d-block" contentLabel="Guthaben aufladen">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Guthaben aufladen</h5>
              <button type="button" className="close" onClick={this.handleClick} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {this.state.submitted ?
              <PaymentModalContent paymentSuccess={this.handleSuccess}
                                   handleReturn={this.handleReturn}
                                   payment_data={this.state.payment_data} /> :
              <GenericReactForm namespace="add_funds" url="/usercp/webhosting/add_funds"
                                defaults={{amount: this.props.amount}}
                                dispatch={this.handleForm}>

                <div className="modal-body">
                  <p>Hier kannst Du auswählen, wie viel Guthaben Du aufladen möchtest. Du erhälst eine Quittung über die Aufladung per E-Mail.</p>

                  {!!this.props.current_balance && this.props.current_balance < 0 && <p className="alert text-danger">Derzeit beträgt Dein Kontostand {format_fractional_price(this.props.current_balance)}. Bitte gleiche diesen Betrag aus!</p>}

                  <StringInput required type="number" name="amount" label="Betrag" step="0.01" />
                </div>

                <div className="modal-footer">
                  <button onClick={this.handleClick} className="btn btn-secondary">abbrechen</button> <SubmitButton icon="euro" text="Guthaben aufladen" />
                </div>
              </GenericReactForm>
            }
          </div>
        </div>
      </Modal>
    </div>;
  }
}

AddFundsButton.propTypes = {
  current_balance: PropTypes.number,
  amount: PropTypes.number.isRequired
}

AddFundsButton.defaultProps = {
  btnClass: "btn-primary btn btn-lg"
}

export default AddFundsButton;
