import { FaIcon } from 'client/js/util/layout_utils';
import ItemErrors from '../item_errors';

const RecurringItem = (props) => {
  return <tr className={props.valid ? null : "error"}>
    <td>
      <p className="title">{props.name}</p>
      {props.children}
      <ItemErrors errors={props.errors} />
    </td>
    <td></td>
    <td className="price">
      {format_fractional_price(props.setup_price)}
    </td>

    {props.undiscounted_first_amount > props.amount ?
      <td className="price">
        <div className="old-price">statt {format_fractional_price(props.undiscounted_first_amount)}</div>
        {format_fractional_price(props.amount)}
        <br />
        <small>für {i18n_c('month', props.billing_cycle)}</small>
      </td> : <td className="price">
        {format_fractional_price(props.amount)}
        <br />
        <small>für {i18n_c('month', props.billing_cycle)}</small>
      </td>
    }
    <td className="price">
      danach {format_fractional_price(props.regular_payment_amount)}
      <br />
      <small>{i18n_c('every_month', props.billing_cycle)}</small>
    </td>
    <td className="pl-0">
      {props.removeItem && <a className="remove_from_cart" href={`/cart/remove_item?id=${props.id}`} onClick={(e) => { e.preventDefault(); props.removeItem(props.id) }}><FaIcon name="trash fa-lg" /></a>}
    </td>
  </tr>
}

export default RecurringItem;
