import { FaIcon } from 'client/js/util/layout_utils';
import PropTypes from 'prop-types';
import Cards from 'react-credit-cards';
import Modal from 'react-modal';
import { CardElement, Elements, StripeProvider, injectStripe } from 'react-stripe-elements';

import 'react-credit-cards/lib/styles-compiled.css';

const xhrToError = (xhr) => {
  if(xhr.status != 400)
    return `Fehler ${xhr.status}`;

  const response = JSON.parse(xhr.responseText);
  return [i18n_t(`cc_errors.${response.error.code}`), `(${response.error.message})`].join(' ');
}

const handleAction = (stripe, response) => {
  return new Promise((resolve, reject) => {
    stripe.handleCardPayment(
      response.payment_intent_client_secret
    ).then((result) => {
      if (result.error) {
        reject(result.error.message);
      } else {
        resolve(result);
      }
    });
  });
}

class CreditCardForm extends React.Component {
  state = {}

  handlePayment = () => {
    this.setState({submitting: true, form_error: null});

    this.props.stripe.createPaymentMethod(
      'card'
    ).then((result) => {
      if(result.error) {
        // TODO: handle error
      } else {
        $.post('/checkout/payment/credit_card/create_intent', {
          order_id: this.props.order_id, payment_method_id: result.paymentMethod.id, save_card: this.state.save_card
        }).done((response) => {
          if (response.error) {
            this.setState({submitting: false, form_error: 'TODO'});
            // Show error from server on payment form
          } else if (response.requires_action) {
            // Use Stripe.js to handle required card action
            // this.handleAction(response);
            handleAction(this.props.stripe, response).then(
              () => this.props.setSuccess()
            ).catch(
              (error) => {
                let i18n_err;

                if(error.error.type == 'card_error' && error.error.code) {
                  i18n_err = I18n_usercp.cc_errors[error.error.code];
                } else {
                  i18n_err = error.error.message;
                }

                this.setState({submitting: false, form_error: i18n_err});
              }
            );
          } else {
            // Show success message
            this.props.setSuccess();
          }
        }).fail((xhr) => {
          this.setState({submitting: false, form_error: xhrToError(xhr)});
          // handle xhr error
        })
      }
    })
  }

  handleSaveClick = (e) => {
    if(this.state.submitting)
      return;
    this.setState({save_card: e.target.checked});
  }

  render() {
    return <>
          <div className="modal-body">
            <p className="text-center">
              <small>Kreditkartendaten werden an Stripe, Inc. übertragen und dort PCI-DSS-compliant gespeichert.</small>
            </p>

            <div className="credit-card-inputs mb-2">
              <CardElement className="form-control" classes={{ invalid: 'is-invalid' }} style={{ base: { fontSize: '1.25rem' }}} hidePostalCode={true} />
              {this.state.form_error && <div className="help-block payment-errors text-danger text-center">{this.state.form_error}</div>}
            </div>

            <div className="form-group text-center">
              <div className="custom-control d-inline-block custom-checkbox">
                <input className="custom-control-input" id="make_payment_save_credit_card" name="save_card"
                       type="checkbox"
                       checked={this.state.save_card}
                       onChange={this.handleSaveClick} />
                <label className="custom-control-label" htmlFor="make_payment_save_credit_card">
                  Kreditkarte merken
                </label>
              </div>
            </div>

            {!!this.props.backToList &&
              <p className="text-center"><button onClick={this.props.backToList} className="small"><FaIcon name="caret-left" /> zurück zur Kreditkarten-Liste</button></p>}
          </div>

          <div className="modal-footer">
            <button className="btn" onClick={this.props.handleReturn}><FaIcon name="caret-left" /> zurück zur Auswahl</button>
            <button className="btn btn-primary" onClick={this.handlePayment} disabled={!!this.state.submitting}><FaIcon name="euro" /> {format_fractional_price(this.props.amount)} bezahlen</button>
          </div>
        </>;
  }
}

CreditCardForm.props = {
  onReturnClick: PropTypes.func.isRequired,
  backToList: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
}

const InjectedCreditCardForm = injectStripe(CreditCardForm);

class UseCreditCard extends React.Component {
  state = {loading: true, submitted: false, number: "", exp: "", cvc: "", stripe: null}

  componentDidMount() {
    this.loadPaymentMethods();

    if(typeof(window.Stripe) === 'undefined')
      $.getScript('https://js.stripe.com/v3/', () => {
        this.setState({stripe: window.Stripe(window.StripePublishableKey)});
      });
    else
      this.setState({stripe: window.Stripe(window.StripePublishableKey)});
  }

  loadPaymentMethods = () => {
    $.get('/usercp/payment_methods.json').done((data) => {
      const credit_cards = data.payment_methods.filter((i) => i.type == 'credit_card');

      this.setState({loading: false,
                     payment_methods: credit_cards,
                     payment_method: credit_cards.reverse()[0]});
    }).fail(() => {
      window.setTimeout(this.loadPaymentMethods, 3000);
    });
  }

  handleFocus = (e) => {
    this.setState({focus: e.target.name});
  }

  handleSaveClick = (e) => {
    this.setState({save_card: e.target.checked});
  }

  chargePaymentMethod = (e) => {
    e.preventDefault();
    this.setState({submitting: true, formError: null});
    $.post(`/checkout/payment/credit_card/create_intent/${this.state.payment_method.id}`, {order_id: this.props.order_id}).done((response) => {
      if (response.error) {
        this.setState({submitting: false, form_error: 'TODO'});
        // Show error from server on payment form
      } else if (response.requires_action) {
        handleAction(this.state.stripe, response).then(
          () => this.setState({success: true})
        ).catch((error) => {
          this.setState({formError: error, submitting: false});
        });
      } else {
        this.setState({success: true});
      }
    }).fail((xhr) => {
      this.handleXhrError(xhr);
    })
  }

  dispatch = (action, original_event, data) => {
    if(action == 'value_changed') {
      this.setState((prevState) => {
        prevState[data.name] = data.value;
        return prevState;
      })
    }
  }

  handleXhrError = (xhr) => {
    let i18n_err;
    if(xhr.status == 400) {
      let response = JSON.parse(xhr.responseText);

      if(response.error.code)
        i18n_err = i18n_t(`cc_errors.${response.error.code}`);
      else
        i18n_err = response.error.message;
    } else {
      i18n_err = `Fehler ${xhr.status}`
    }

    this.setState({formError: i18n_err, submitting: false});
  }

  selectPaymentMethod = (e, payment_method) => {
    e.preventDefault();
    this.setState({payment_method: payment_method, form_open: false});
  }

  handleExistingPaymentMethod = (e) => {
    e.preventDefault();
  }

  handleAdd = (e) => {
    e.preventDefault();
    this.setState({formError: null, form_open: true});
  }

  cancelAdd = (e) => {
    e.preventDefault();
    this.setState({formError: null, form_open: false});
  }

  setSuccess = () => {
    this.setState({success: true});
  }

  render() {
    if(this.state.loading) {
      return <div>
          <div className="modal-body">
            <div className="css-spinloader"></div>
          </div>
        </div>;
    }

    if(this.state.success) {
      const clickHandler = (e) => {
        e.preventDefault();
        this.props.paymentSuccess();
      }

      return <div>
          <div className="modal-body">
            <div className="text-center">
              <h4 className="text-success">
                <FaIcon name="thumbs-o-up fa-3x mb-3" />
                <br/>
                Zahlung erfolgreich!
              </h4>

              <p className="mb-3">
                Vielen Dank! Die Zahlung wurde erfolgreich durchgeführt.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="btn btn-primary float-right" onClick={clickHandler}><FaIcon name="check" /> fertig!</a>
          </div>
        </div>;
    }

    const payment_methods_list = this.state.payment_methods;
    if(payment_methods_list && payment_methods_list.length && !this.state.form_open) {

      return <div>
          <div className="modal-body">
            <div className="credit-card-modal-form mb-4">
              <Cards number={this.state.payment_method.card_number.replace(/\s/g, '')}
                name="MAX MUSTERMANN"
                expiry={this.state.payment_method.valid_through.replace('/20', '/').replace(/^([0-9])\//, '0$1/')}
                issuer={this.state.payment_method.card_brand}
                cvc="000"
                focus="number"
                preview />
              {this.state.formError && <div className="help-block payment-errors text-danger text-center">{this.state.formError}</div>}
            </div>

            <div className="list-group list-group-flush credit-cards-list mb-3">
              {payment_methods_list.map((card) => (
                <a key={card.id} className={`list-group-item list-group-item-action ${card.id == this.state.payment_method.id ? 'active' : null}`} href='#'
                   onClick={(e) => this.selectPaymentMethod(e, card)}>
                  <div className="my-1">{card.friendly_name}</div>
                </a>
                ))}
              <a className="list-group-item list-group-item-action" href='#' onClick={this.handleAdd}>
                  <div className="my-1"><FaIcon name="plus" /> andere Kreditkarte</div>
                </a>
            </div>

          </div>
          <div className="modal-footer">
            <a href="#" className="btn" onClick={this.props.handleReturn}><FaIcon name="caret-left" /> zurück zur Auswahl</a>
            <button className="btn btn-primary" onClick={this.chargePaymentMethod} disabled={!!this.state.submitting}><FaIcon name="euro" /> {format_fractional_price(this.props.amount)} bezahlen</button>
          </div>
        </div>;
    }

    return <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <InjectedCreditCardForm order_id={this.props.order_id}
                      amount={this.props.amount}
                      handleReturn={this.props.handleReturn}
                      setSuccess={this.setSuccess}
                      backToList={this.state.payment_methods && this.state.payment_methods.length && this.cancelAdd} />
              </Elements>
            </StripeProvider>;
  }
}

UseCreditCard.propTypes = {
  next: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
  order_id: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  paymentSuccess: PropTypes.func.isRequired
}

class UseSepa extends React.Component {
  state = {loading: true, submitted: false}

  componentDidMount() {
    this.loadPaymentMethods();
  }

  loadPaymentMethods = () => {
    $.get('/usercp/payment_methods.json').done((data) => {
      const payment_methods = data.payment_methods.filter((i) => i.type == 'direct_debit');

      this.setState({loading: false,
                     payment_methods: payment_methods,
                     payment_method: payment_methods.reverse()[0]});
    }).fail(() => {
      window.setTimeout(this.loadPaymentMethods, 3000);
    });
  }

  selectPaymentMethod = (e, payment_method) => {
    this.setState({payment_method: payment_method});
    e.preventDefault();
  }

  chargePaymentMethod = (e) => {
    e.preventDefault();
    this.setState({submitting: true, formError: null});

    $.post(
      '/checkout/payment/direct_debit/confirm',
      {payment_method_id: this.state.payment_method.id, order_id: this.props.order_id}
    ).done((data) => {
      this.setState({success: true, invoice: data.invoice});
    }).fail((xhr) => {
      this.handleXhrError(xhr);
    });
  }

  handleXhrError = (xhr) => {
    let i18n_err;
    if(xhr.status == 400) {
      let response = JSON.parse(xhr.responseText);

      i18n_err = response.error.message;
    } else {
      i18n_err = `Fehler ${xhr.status}`
    }

    this.setState({formError: i18n_err, submitting: false});
  }

  render() {
    if(this.state.loading) {
      return <>
          <div className="modal-body">
            <div className="css-spinloader"></div>
          </div>
        </>;
    }

    if(this.state.success) {
      const clickHandler = (e) => {
        e.preventDefault();
        this.props.paymentSuccess();
      }

      return <>
          <div className="modal-body">
            <div className="text-center">
              <h4 className="text-success">
                <FaIcon name="thumbs-o-up fa-3x mb-3" />
                <br/>
                Zahlung erfolgreich!
              </h4>

              <p className="mb-3">
                Vielen Dank! Die Rechnung Nr. {this.state.invoice.id} wird vom ausgewählten Bankkonto eingezogen.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="btn btn-primary float-right" onClick={clickHandler}><FaIcon name="check" /> fertig!</a>
          </div>
        </>;
    }

    const payment_methods_list = this.state.payment_methods;

    return <>
        <div className="modal-body">
          {!!this.state.formError && <p className="help-block payment-errors text-danger text-center">{this.state.formError}</p>}
          <div className="list-group list-group-flush credit-cards-list mb-3">
            {payment_methods_list.map((payment_method) => (
              <a key={payment_method.id}
                 className={`list-group-item list-group-item-action ${payment_method.id == this.state.payment_method.id ? 'active' : null}`}
                 href='#'
                 disabled={!payment_method.verified || payment_method.blocked}
                 onClick={(e) => this.selectPaymentMethod(e, payment_method)}>
                <div className="my-1">
                  <strong>{payment_method.iban}</strong>
                  {' '}
                  {!payment_method.verified && <span className="badge badge-warning">unbestätigt</span>}
                  {payment_method.blocked && <span className="badge badge-danger">gesperrt</span>}
                </div>
                <div className="my-1">{payment_method.account_holder} ({payment_method.mandate_id})</div>
              </a>
              ))}
          </div>

        </div>
        <div className="modal-footer">
          <a href="#" className="btn" onClick={this.props.handleReturn}><FaIcon name="caret-left" /> zurück zur Auswahl</a>
          <button className="btn btn-primary" onClick={this.chargePaymentMethod} disabled={!this.state.payment_method || !!this.state.submitting}><FaIcon name="euro" /> {format_fractional_price(this.props.amount)} bezahlen</button>
        </div>
      </>;
  }
}

UseSepa.propTypes = {
  handleReturn: PropTypes.func.isRequired,
  order_id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  paymentSuccess: PropTypes.func.isRequired
}

class UsePaypal extends React.Component {
  state = { loading: true, submitted: false }

  componentDidMount() {
    this.loadPaymentMethods();
  }

  loadPaymentMethods = () => {
    $.get('/usercp/payment_methods.json').done((data) => {
      const payment_methods = data.payment_methods.filter((i) => i.type == 'paypal_billing_agreement');

      this.setState({
        loading: false,
        payment_methods: payment_methods,
        payment_method: payment_methods.reverse()[0]
      });
    }).fail(() => {
      window.setTimeout(this.loadPaymentMethods, 3000);
    });
  }

  selectPaymentMethod = (e, payment_method) => {
    this.setState({ payment_method: payment_method });
    e.preventDefault();
  }

  chargePaymentMethod = (e) => {
    e.preventDefault();
    this.setState({ submitting: true, formError: null });

    $.post(
      '/checkout/payment/paypal_billing_agreement/confirm',
      { payment_method_id: this.state.payment_method.id, order_id: this.props.order_id }
    ).done((data) => {
      this.setState({ success: true, invoice: data.invoice });
    }).fail((xhr) => {
      this.handleXhrError(xhr);
    });
  }

  handleXhrError = (xhr) => {
    let i18n_err;
    if (xhr.status == 400) {
      let response = JSON.parse(xhr.responseText);

      i18n_err = response.error.message;
    } else {
      i18n_err = `Fehler ${xhr.status}`
    }

    this.setState({ formError: i18n_err, submitting: false });
  }

  render() {
    if (this.state.loading) {
      return <>
        <div className="modal-body">
          <div className="css-spinloader"></div>
        </div>
      </>;
    }

    if (this.state.success) {
      const clickHandler = (e) => {
        e.preventDefault();
        this.props.paymentSuccess();
      }

      return <>
        <div className="modal-body">
          <div className="text-center">
            <h4 className="text-success">
              <FaIcon name="thumbs-o-up fa-3x mb-3" />
              <br />
              Zahlung erfolgreich!
            </h4>

            <p className="mb-3">
              Vielen Dank! Die Rechnung Nr. {this.state.invoice.id} wurde dem ausgewählten PayPal-Konto belastet.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn btn-primary float-right" onClick={clickHandler}><FaIcon name="check" /> fertig!</a>
        </div>
      </>;
    }

    const payment_methods_list = this.state.payment_methods;

    return <>
      <div className="modal-body">
        <p>Bitte wähle, ob Du eine bestehenden PayPal-Zahlungmethode nutzen oder eine neue Zahlung per PayPal Express Checkout starten möchtest:</p>

        {!!this.state.formError && <p className="help-block payment-errors text-danger text-center">{this.state.formError}</p>}

        <div className="list-group list-group-flush credit-cards-list mb-3">
          {payment_methods_list.map((payment_method) => (
            <a key={payment_method.id}
              className={`list-group-item list-group-item-action ${payment_method.id == this.state.payment_method.id ? 'active' : null}`}
              href='#'
              disabled={!payment_method.verified || payment_method.blocked}
              onClick={(e) => this.selectPaymentMethod(e, payment_method)}>
              <div className="my-1">
                <strong>PayPal-Konto {payment_method.payer_email}</strong>
                {' '}
                {payment_method.blocked && <span className="badge badge-danger">gesperrt</span>}
              </div>
              <div className="my-1">Billing Agreement {payment_method.billing_agreement_id} vom {dayjs(payment_method.created_at).format('ll')}</div>
            </a>
          ))}

          <a className="list-group-item list-group-item-action"
            href={this.props.paypal_url}
            rel="noopener noreferrer">
            <div className="my-1"><strong>PayPal Express Checkout</strong></div>
            <div className="my-1">Startet eine neue PayPal-Zahlung auf der PayPal-Seite</div>
          </a>
        </div>

      </div>
      <div className="modal-footer">
        <a href="#" className="btn" onClick={this.props.handleReturn}><FaIcon name="caret-left" /> zurück zur Auswahl</a>
        <button className="btn btn-primary" onClick={this.chargePaymentMethod} disabled={!this.state.payment_method || !!this.state.submitting}><FaIcon name="euro" /> {format_fractional_price(this.props.amount)} bezahlen</button>
      </div>
    </>;
  }
}

UsePaypal.propTypes = {
  paypal_url: PropTypes.string.isRequired,
  handleReturn: PropTypes.func.isRequired,
  order_id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  paymentSuccess: PropTypes.func.isRequired
}

class UseAccountBalance extends React.Component {
  state = { loading: false, submitted: false }

  chargePaymentMethod = (e) => {
    e.preventDefault();
    this.setState({ submitting: true, formError: null });

    $.post(
      '/checkout/payment/account_balance/confirm',
      { order_id: this.props.order_id }
    ).done((data) => {
      this.setState({ success: true, invoice: data.invoice });
    }).fail((xhr) => {
      this.handleXhrError(xhr);
    });
  }

  handleXhrError = (xhr) => {
    let i18n_err;
    if (xhr.status == 400) {
      let response = JSON.parse(xhr.responseText);

      i18n_err = response.error.message;
    } else {
      i18n_err = `Fehler ${xhr.status}`
    }

    this.setState({ formError: i18n_err, submitting: false });
  }

  render() {
    if (this.state.loading) {
      return <div>
        <div className="modal-body">
          <div className="css-spinloader"></div>
        </div>
      </div>;
    }

    if (this.state.success) {
      const clickHandler = (e) => {
        e.preventDefault();
        this.props.paymentSuccess();
      }

      return <>
        <div className="modal-body">
          <div className="text-center">
            <h4 className="text-success">
              <FaIcon name="thumbs-o-up fa-3x mb-3" />
              <br />
              Zahlung erfolgreich!
            </h4>

            <p className="mb-3">
              Vielen Dank! Die Rechnung Nr. {this.state.invoice.id} wurde gerade ausgestellt und ist bereits bezahlt.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <a href="#" className="btn btn-primary float-right" onClick={clickHandler}><FaIcon name="check" /> fertig!</a>
        </div>
      </>;
    }

    return <>
      <div className="modal-body">
        {!!this.state.formError && <p className="help-block payment-errors text-danger text-center">{this.state.formError}</p>}

        <p>
          Derzeit ist auf dem Kundenkonto ein <strong>Guthaben von {format_fractional_price(this.props.current_balance)}</strong> vorhanden,
          mit welchem diese Bestellung über {format_fractional_price(this.props.amount)} verrechnet werden kann.
        </p>

        <p>
          Bitte klicke auf &quot;bezahlen&quot;, um die Bestellung mit dem Guthaben zu verrechnen und auszuführen.
        </p>

      </div>
      <div className="modal-footer">
        <a href="#" className="btn" onClick={this.props.handleReturn}><FaIcon name="caret-left" /> zurück zur Auswahl</a>
        <button className="btn btn-primary" onClick={this.chargePaymentMethod} disabled={!!this.state.submitting}><FaIcon name="euro" /> {format_fractional_price(this.props.amount)} bezahlen</button>
      </div>
    </>;
  }
}

UseAccountBalance.propTypes = {
  handleReturn: PropTypes.func.isRequired,
  order_id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  current_balance: PropTypes.number.isRequired,
  paymentSuccess: PropTypes.func.isRequired
}

const MenuItem = (props) => (
  <a className="list-group-item list-group-item-action" href={props.href}
     onClick={props.onClick ? (e) => props.onClick(e, props.type) : null}>
    <img src={props.logo_src} className="my-3 mr-3" style={{maxHeight: '26px', objectFit: 'contain'}} />
    <strong>{props.logo_text}</strong>
    {' '}
    {props.small_text ? <small>{props.small_text}</small> : null}
  </a>
)

MenuItem.propTypes = {
  logo_src: PropTypes.string.isRequired,
  logo_text: PropTypes.string.isRequired,
  small_text: PropTypes.string,
  href: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['credit_card', 'direct_debit', 'credit_transfer', 'paypal', 'sofort', 'psc', 'bitcoin', 'klarna', 'eps']).isRequired,
  onClick: PropTypes.func
}

MenuItem.defaultProps = {
  href: '#'
}

class PaymentModalContent extends React.Component {
  state = {}

  handleClick = (e, type) => {
    e.preventDefault();
    this.setState({type: type});
  }

  handleReturn = (e) => {
    e.preventDefault();
    this.setState({type: null});
  }

  render() {
    let clickHandler = (e) => {
      e.preventDefault();
      this.props.paymentSuccess();
    }

    if (this.state.type == 'account_balance') {
      return <UseAccountBalance order_id={this.props.payment_data.order_id}
                                amount={this.props.payment_data.amount}
                                current_balance={this.props.payment_data.current_balance}
                                handleReturn={this.handleReturn}
                                paymentSuccess={this.props.paymentSuccess} />;
    }

    if(this.state.type == 'credit_card') {
      return <UseCreditCard order_id={this.props.payment_data.order_id}
                            amount={this.props.payment_data.amount}
                            handleReturn={this.handleReturn}
                            paymentSuccess={this.props.paymentSuccess} />;
    }

    if(this.state.type == 'sepa') {
      return <UseSepa order_id={this.props.payment_data.order_id}
                      amount={this.props.payment_data.amount}
                      handleReturn={this.handleReturn}
                      paymentSuccess={this.props.paymentSuccess} />;
    }

    if (this.state.type == 'paypal') {
      return <UsePaypal order_id={this.props.payment_data.order_id}
        paypal_url={this.props.payment_data.urls.paypal}
        amount={this.props.payment_data.amount}
        handleReturn={this.handleReturn}
        paymentSuccess={this.props.paymentSuccess} />;
    }

    if(this.state.type == 'credit_transfer')
      return <>
        <div className="modal-body">
          <p>Bitte verwende folgende Kontodaten für die Banküberweisung:</p>

          <table className="table table-striped">
            <tbody>
              <tr><td>Kontoinhaber:</td><td>TrafficPlex GmbH</td></tr>
              <tr><td>IBAN:</td><td>DE07290400900161677000</td></tr>
              <tr><td>BIC/SWIFT:</td><td>COBADEFFXXX</td></tr>
              <tr><td>Betrag:</td><td><strong>{format_fractional_price(this.props.payment_data.amount)}</strong></td></tr>
              <tr><td>Verwendungszweck:</td><td><strong>{this.props.payment_data.reference_code}</strong></td></tr>
            </tbody>
          </table>

          <p className="text-danger">Bitte gib nur diesen Verwendungszweck exakt genau so an, ohne sonstige Ergänzungen! Dies beschleunigt die Freischaltung und beugt Fehlern bei der Zuordnung vor!</p>

        </div>
        <div className="modal-footer">
          <a href="#" className="btn" onClick={this.handleReturn}><FaIcon name="caret-left" /> zurück zur Auswahl</a>
          <button className="btn btn-primary float-right" onClick={clickHandler}><FaIcon name="check" /> fertig!</button>
        </div>
      </>;

    return <div className="modal-body">
        <div className="list-group list-group-flush payment-methods-list mb-3">
          {!!this.props.payment_data.balance_available && <MenuItem onClick={this.handleClick} type="account_balance" logo_src="/assets/layout/logo-header.svg" logo_text="Kundenkonto-Guthaben" />}
          {!!this.props.payment_data.sepa_available && <MenuItem onClick={this.handleClick} type="sepa" logo_src="/assets/payment/sepadd.png" logo_text="SEPA-Lastschrift" />}
          <MenuItem onClick={this.handleClick} type="credit_card" logo_src="/assets/payment/credit_card.png" logo_text="Kreditkarte" />
          {!this.props.payment_data.paypal_billing_agreement_available && <MenuItem href={this.props.payment_data.urls.paypal} type="paypal" logo_src="/assets/payment/paypal.png" logo_text="PayPal" />}
          {!!this.props.payment_data.paypal_billing_agreement_available && <MenuItem onClick={this.handleClick} type="paypal" logo_src="/assets/payment/paypal.png" logo_text="PayPal" />}
          <MenuItem href={this.props.payment_data.urls.eps} type="eps" logo_src="/assets/payment/eps.png" logo_text="EPS" />
          <MenuItem href={this.props.payment_data.urls.klarna} type="klarna" logo_src="/assets/payment/klarna.svg" logo_text="Klarna" />
          <MenuItem href={this.props.payment_data.urls.bitpay} type="bitcoin" logo_src="/assets/payment/bitcoin.png" logo_text="Bitcoin" />
          <MenuItem href={this.props.payment_data.urls.paysafecard} type="psc" logo_src="/assets/payment/psc.png" logo_text="paysafecard" />
          <MenuItem onClick={this.handleClick} type="credit_transfer" logo_src="/assets/payment/credit_transfer.png" logo_text="Banküberweisung" small_text="(1-2 Werktage)" />
        </div>
        {!!this.props.handleReturn && <div className="btn" onClick={this.props.handleReturn}><FaIcon name="caret-left" /> zurück zum Betrag</div>}
      </div>
  }
}

PaymentModalContent.propTypes = {
  handleReturn: PropTypes.func,
  paymentSuccess: PropTypes.func.isRequired,
  payment_data: PropTypes.shape({
    sepa_available: PropTypes.bool,
    balance_available: PropTypes.bool,
    paypal_billing_agreement_available: PropTypes.bool,
    current_balance: PropTypes.number,
    urls: PropTypes.shape({
      paypal: PropTypes.string.isRequired,
      sofort: PropTypes.string.isRequired,
      eps: PropTypes.string.isRequired,
      klarna: PropTypes.string.isRequired,
      bitpay: PropTypes.string.isRequired,
      paysafecard: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

class PayButton extends React.Component {
  state = {modal_open: false}

  componentDidMount() {
    if(this.props.autostart) {
      this.setState({modal_open: true})
      this.load();
    }
  }

  load = () => {
    $.get(`/usercp/orders/${this.props.order_id}.json`).done((data) => {
      const p = data.order;
      p.order_id = p.id;

      this.setState({payment_data: p});
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    if(!this.state.payment_data && !this.state.modal_open)
      this.load();

    this.setState({modal_open: !this.state.modal_open});
  }

  handleSuccess = () => {
    if(this.props.onSuccess)
      this.props.onSuccess();

    this.setState({paid: true, modal_open: false});
  }

  handleForm = (action, e, data) => {
    if(action != 'form:success')
      return;

    this.setState({payment_data: data.object});
  }

  render() {
    if(this.state.paid && this.props.successElement)
      return this.props.successElement;

    if(this.state.paid)
      return <div className="text-center">
        <h4 className="text-success">
          <video className="success-video mb-3" id="video-manager" muted preload autoPlay>
            <source src="/assets/ani/success.mp4" type="video/mp4" />
          </video>

          <br/>
          Vielen Dank für die Zahlung!
        </h4>
      </div>

    return <div className={this.props.wrapperClass}>
      <button className={this.props.btnClass} onClick={this.handleClick} disabled={!!this.state.modal_open || this.state.paid}>{this.state.paid ? 'Guthaben erfolgreich aufgeladen!' : [<FaIcon key={0} name="euro" />, " Zahlung starten"]}</button>

      <Modal isOpen={this.state.modal_open} className="modal fade in show d-block" contentLabel="Guthaben aufladen">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Zahlung ausführen</h5>
              <button type="button" className="close" onClick={this.handleClick} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {this.state.payment_data ?
              <PaymentModalContent paymentSuccess={this.handleSuccess}
                                   handleReturn={this.handleReturn}
                                   payment_data={this.state.payment_data} /> : <div className="modal-body"><div className="css-spinloader"></div></div>
            }
          </div>
        </div>
      </Modal>
    </div>;
  }
}

PayButton.propTypes = {
  order_id: PropTypes.number.isRequired,
}

PayButton.defaultProps = {
  btnClass: "btn-primary btn btn-lg"
}

export { PayButton, PaymentModalContent, handleAction, xhrToError };
export default PayButton;
