import React, { useState, useEffect } from "react";

function ChangeQuantity({ id, min, max, value: initialQuantity, onChange }) {
  const [isMobile, setIsMobile] = useState(false);
  const [localQuantity, setLocalQuantity] = useState(initialQuantity);
  const [lastQuantityUpdate, setLastQuantityUpdate] = useState(null);

  const updateParentQuantity = (newQuantity) => {
    if (newQuantity === lastQuantityUpdate) {
      return;
    }

    setLastQuantityUpdate(newQuantity);
    onChange(newQuantity);
  }

  useEffect(() => {
    // Sync local state with initial quantity from props when the component mounts or the initial quantity changes
    setLocalQuantity(initialQuantity);
  }, [initialQuantity]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    // Initial check
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const increaseQuantity = () => {
    if (!max || localQuantity < max) {
      const newQuantity = localQuantity + 1;
      setLocalQuantity(newQuantity);
      updateParentQuantity(newQuantity);
    }
  };

  const decreaseQuantity = () => {
    if (!min || localQuantity > min) {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      updateParentQuantity(newQuantity);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "" || (/^\d+$/.test(value) && (!max || Number(value) <= max) && (!min || Number(value) >= min))) {
      setLocalQuantity(value === "" ? "" : Number(value));

      // if the element is not focused, update the parent quantity
      if (document.activeElement !== event.target) {
        updateParentQuantity(value === "" ? "" : Number(value));
      }
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      handleBlur();
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    let finalQuantity = localQuantity;
    if (localQuantity === "" || (min && localQuantity < min)) {
      finalQuantity = min || 1;
    } else if (max && localQuantity > max) {
      finalQuantity = max;
    }

    setLocalQuantity(finalQuantity);
    updateParentQuantity(finalQuantity);
  };

  return (
    <>
      {isMobile ? (
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              type="button"
              className="btn"
              onClick={decreaseQuantity}
              disabled={localQuantity <= min}
            >
              -
            </button>
          </div>
          <input
            type="text"
            className="item_quantity form-control"
            inputMode="numeric"
            id={`quantity_${id}`}
            name={`quantity[${id}]`}
            value={localQuantity}
            onChange={handleChange}
            onKeyDown={onKeyPress}
            onBlur={handleBlur}
          />
          <div className="input-group-append">
            <button
              type="button"
              className="btn"
              onClick={increaseQuantity}
              disabled={localQuantity >= max}
            >
              +
            </button>
          </div>
        </div>
      ) : (
        <input
          type="number"
          className="item_quantity form-control"
          id={`quantity_${id}`}
          name={`quantity[${id}]`}
          value={localQuantity}
          min={min}
          max={max}
          onChange={handleChange}
          onKeyDown={onKeyPress}
          onBlur={handleBlur}
        />
      )}
    </>
  );
}

export default ChangeQuantity;
