const ErrorMessage = ({name, messages}) => {
  if(name === "base") {
    return messages.join("");
  }

  return `${i18n_t(name)} ${messages.join("")}`;
}

const ItemErrors = (props) => {
  return <div className='errors'>
      {Object.keys(props.errors).map((name) => (<ErrorMessage key={name} name={name} messages={props.errors[name]} />))}
    </div>;
}

export default ItemErrors;
