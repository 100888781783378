import RecurringItem from './recurring_item';

const Domains = (props) => {
  if(!props.used_domains.length)
    return null;

  return <>
      <strong>Ausgewählte Inklusiv-Domains:</strong>
      <ul>
      {props.used_domains.map((domain_row) => (
        <li key={[domain_row[1], domain_row[0]].join(':')}>{i18n_t(`domain_modes.${domain_row[1]}`)} {domain_row[0]}</li>
      ))}
      </ul>
    </>
}

const Description = (props) => {
  return <>
    <strong>Vertragsdetails</strong>
    <ul>
      {props.first_interval && <li>Neukundenrabatt: {format_fractional_price(props.first_interval_price)} pro Monat für {i18n_c("month", props.first_interval_months)}</li>}
      {props.first_interval && <li>danach {format_fractional_price(props.price)} pro Monat</li>}
      {!props.first_interval && <li>{format_fractional_price(props.price)} pro Monat</li>}
      {props.free_setup ? <li>kostenlose Einrichtung</li> : <li>Einrichtungsgebühr {format_fractional_price(props.setup_price)} einmalig</li>}
      <li>Abrechnung {i18n_c("month", props.billing_cycle)} im Voraus</li>
      <li>Laufzeit {i18n_c("month", props.contract_period)}</li>
    </ul>
    <strong>Produktdetails</strong>
    <ul>
      {props.inclusive_domains && props.inclusive_tlds.length > 0 ?
        <li>
          {i18n_c("shared_hosting_offer.inclusive_domains", props.inclusive_domains)} ({props.inclusive_tlds.map((item, index) => (`.${item}`)).join(', ')})
        </li> : null}
      <li>
        {props.disk_space_gb} GB Speicherplatz
      </li>
      <li>Traffic unbegrenzt</li>
      <li>kostenlose, automatische SSL-Zertifikate per Let's Encrypt</li>
      <li>{props.mysql_space_gb} GB MySQL-Speicher, unbegrenzt MySQL-Datenbanken</li>
      <li>{props.email_space_gb} GB E-Mail-Speicher, unbegrenzt Postfächer & Weiterleitungen</li>
    </ul>

    <Domains used_domains={props.used_domains} />
    <a href="/domain-check">Domain jetzt hinzufügen</a> oder jederzeit später über die Verwaltung registrieren
  </>
}

const SharedHostingOffer = (props) => (
  <RecurringItem {...props}>
    <Description {...props} />
  </RecurringItem>
)

export default SharedHostingOffer;
