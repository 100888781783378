import {FaIcon} from 'client/js/util/layout_utils';

const EmptyCart = () => {
  return <div className="empty_cart mb-5">
            <div className="text-center">
              <h2>Der Warenkorb ist leer. Jetzt eine günstige Domain hinzufügen!</h2>
              <form acceptCharset="UTF-8" action="/domain-check" className="simple_form fat_domain_check" id="domain_check_form" method="post">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text">www.</div>
                  </div>
                  <input aria-required="true" className="string required form-control" id="domain_sld" maxLength="63" name="domain[sld]" placeholder="meine-domain" required="required" size="63" type="text" />
                  <div className="input-group-append">
                    <div className="input-group-text">.de/.com/.net/...</div>
                  </div>
                </div>
                <button className="btn btn-success btn-lg" data-disable-with="Okay, Captain!" name="button" type="submit">
                  <FaIcon name="search" />
                  jetzt prüfen
                </button>
              </form>
            </div>
          </div>
}

export default EmptyCart;
