const Product = (props) => {
  const product = props.product;

  return (
    <div className="card">
      <h5 className="card-header">{product.title}</h5>
      <div className="card-body">
        <p className="card-text">{product.description}</p>
        <a className="btn btn-secondary" href={product.add_to_cart_url} onClick={(e) => props.add_item(e, product)}>
          <i className="fa fa-plus"></i>
          {' '}
          zum Warenkorb hinzufügen
        </a>
      </div>
    </div>
  );
}

const ProductList = (props) => (
  <div className="product-list card-columns card-columns-3">
    {props.products.map((product) => (<Product key={product.title} product={product} add_item={props.add_item} />))}
  </div>
)

const Crosselling = (props) => {
  const data = props.data;
  if(!data)
    return null;

  return (
    <React.Fragment>
      <ProductList products={data.products} add_item={props.add_item} />
      {data.products && data.products.length && data.expiring_domains && data.expiring_domains.length ? <hr/> : null}
      <ProductList products={data.expiring_domains} add_item={props.add_item} />
    </React.Fragment>
  );
}

export default Crosselling;
