import { CollapsibleMenu, get_safe } from 'client/js/util/app_nav';
import { withLoading } from 'client/js/util/rest_utils';
import { FaIcon } from 'client/js/util/layout_utils';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { MemoryRouter, BrowserRouter, NavLink, Route } from 'react-router-dom';
import { set_safe } from '../util/app_nav';
import { NextNavLink as RealNextNavLink, NextNavSubLink as RealNextNavSublink, NextCategory } from 'client/js/navigation';
import { isServer } from 'react-on-rails';

const NewRealNavLink = (props) => (<li className="nav-item"><NavLink className="nav-link" {...props} /></li>);
const NewLegacyNavLink = (props) => (<li className="nav-item"><OldNavLink to={props.to}>{props.children}</OldNavLink></li>);

const OldNavLink = (props) => (
  /* eslint-disable-next-line react/no-children-prop */
  <Route path={props.to} children={({ match }) => (
    <a className={match ? 'nav-link active' : 'nav-link'} href={`/usercp${props.to}`}>{props.children}</a>
  )}/>
)

OldNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const AccountStatusText = (props) => {
  return <small className={`text-${props.className}`}>{props.children}</small>;
}

AccountStatusText.propTypes = {
  className: PropTypes.string.isRequired,
}

AccountStatusText.defaultProps = {
  className: 'muted'
}

class BalanceDueModal extends React.Component {
  state = {open: !get_safe('balance_due_modal')}

  handleClick = (e) => {
    e.preventDefault();
    this.setState({open: false});
    set_safe('balance_due_modal', true);
  }

  render() {
    return (
      <Modal isOpen={this.state.open} className="modal fade in show d-block" contentLabel="Offene Zahlungen">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger">Offene Zahlungen</h5>
              <button type="button" className="close" onClick={this.handleClick} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <p>Auf Deinem Kundenkonto sind derzeit Rechnungen offen:</p>

              <p>
                <strong>{format_fractional_price(Math.abs(this.props.due_amount))}</strong>
              </p>

              <p>Das Kundenkonto ist in Mahnstufe {this.props.dunning_level}, die Frist für die Zahlung ist der {dayjs(this.props.due_date).format('L')}.</p>

              <p>Bitte gleiche den offenen Betrag in der Verwaltung unter "<a href="/usercp/invoices">Rechnungen</a>" aus. Sollte der Account aufgrund des Zahlungsrückstands bereits gesperrt sein wird er innerhalb eines Werktags nach Zahlungseingang entsperrt.</p>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const AccountStatus = (props) => {
  if(props.data.due_date) {
    const modal = <BalanceDueModal {...props.data} />;

    let text = null;
    if(props.data.dunning_level == 0)
      text = <AccountStatusText className="warning">{format_fractional_price(Math.abs(props.data.due_amount))} offen, Zahlungsfrist {dayjs(props.data.due_date).format("L")}</AccountStatusText>;
    else
      text = <AccountStatusText className="danger">{format_fractional_price(Math.abs(props.data.due_amount))} offen - Mahnstufe {props.data.dunning_level}, Frist {dayjs(props.data.due_date).format("L")}</AccountStatusText>;

    return <React.Fragment>{modal}{text}</React.Fragment>;

  if(props.data.status == 'unconfirmed')
    return <AccountStatusText className="danger">E-Mail-Adresse nicht bestätigt</AccountStatusText>;

  if(props.data.status == 'archived')
    return <AccountStatusText className="danger">Archiviert</AccountStatusText>;

  if(props.data.status == 'verification_required')
    return <AccountStatusText className="danger">Verifizierung nötig</AccountStatusText>;
  }

  return <AccountStatusText></AccountStatusText>;
}

const AccountStatusContainer = withLoading(AccountStatus);

const TopPanel = () => {
  return <div>
      Hallo {document.lima_username}!
      <br/>
      <AccountStatusContainer url="/usercp/account_status.json" key_name="account_status" inline_spinner />
    </div>
}

class UsercpMenu extends React.Component {
  state = {open: false}

  handleToggle = (e) => {
    e.preventDefault();
    this.setState((state) => ({open: !state.open}));
  }

  render() {
    let cls = this.props.standalone ? [] : ["col-12", "col-md-3", "col-xl-2", "bd-sidebar"]
    const NewNavLink = this.props.standalone ? NewLegacyNavLink : NewRealNavLink;

    if(!this.state.open)
      cls.push('closed');

    return <div className={cls.join(' ')}>
      <div className="toggler">
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="bd-search align-items-center">
        <TopPanel />
        <button onClick={this.handleToggle} aria-controls="bd-docs-nav" aria-expanded="false" aria-label="Toggle docs navigation" className="btn btn-link bd-search-docs-toggle d-md-none p-0 ml-auto text-secondary" type="button">
          <i className="fa fa-bars fa-2x"></i>
        </button>
      </div>
      <nav className="bd-links px-3" id="menuNav">
        <ul className="nav flex-column">
          <ul className="nav nav-pills nav-stacked flex-column mb-3">
            <NewNavLink to="/webhosting">Webhosting-Paket</NewNavLink>
            <NewNavLink isActive={(_, { pathname }) => (pathname.match(/\/(email|mailboxes|aliases|mailbox_imports)/) && !pathname.match(/^\/email-adresse/))} to="/email">E-Mail-Adressen</NewNavLink>
            <NewNavLink to="/tickets">Support-Tickets</NewNavLink>
          </ul>
          <CollapsibleMenu title="Websites & Domains">
            <NewNavLink to="/websites" isActive={(_, { pathname }) => (pathname.match(/^\/websites/) && !pathname.match(/\/websites\/statistics/))}>Websites</NewNavLink>
            <NewNavLink to={`/domains`}>Domains</NewNavLink>
            <NewNavLink to={`/wordpress`}>WordPress</NewNavLink>
            <NewNavLink to={`/lets-encrypt`}>SSL</NewNavLink>
          </CollapsibleMenu>
          <CollapsibleMenu title="Webspace">
            <NewNavLink to={`/ssh_accounts`}>SSH</NewNavLink>
            <NewNavLink to="/ftp_accounts">FTP-Zugang</NewNavLink>
            <NewNavLink to="/websites/statistics">Statistiken</NewNavLink>
            <NewNavLink to="/softwares">Software-Installer</NewNavLink>
            <NewNavLink to="/memcached">Memcached</NewNavLink>
            <NewNavLink to={`/php_version`}>PHP-Version</NewNavLink>
            <NewNavLink to={`/php_error_log`}>PHP-Log</NewNavLink>
            <NewNavLink to="/phpmails">PHP-Mailversand</NewNavLink>
            <NewNavLink to="/webspace_backups">Backups</NewNavLink>
            <NewNavLink to="/cronjobs">Cronjobs</NewNavLink>
          </CollapsibleMenu>
          <CollapsibleMenu title="MySQL-Datenbanken">
            <NewNavLink to={`/databases`}>Datenbanken</NewNavLink>
            <NewNavLink to={`/database_users`}>Datenbank-Benutzer</NewNavLink>
            <NewNavLink to={`/phpmyadmin`}>phpMyAdmin</NewNavLink>
          </CollapsibleMenu>
          <CollapsibleMenu title="vServer & Cloud">
            <NewNavLink isActive={(_, { pathname }) => (pathname.match(/\/virtual_machines/) && !pathname.match(/\/virtual_machines\/snapshots/) && !pathname.match(/\/virtual_machines\/charges/))} to={`/virtual_machines`}>Virtuelle Maschinen</NewNavLink>
            <NewNavLink to={`/virtual_machines/snapshots`}>Snapshots</NewNavLink>
            <NewNavLink to={`/virtual_machines/charges`}>Abrechnung</NewNavLink>
          </CollapsibleMenu>
        </ul>
      </nav>
    </div>
  }
}

const FakeNextNavLink = (props) => {
  return (
    <li className="nav-item">
      <a className="nav-link" role="menuitem" href={`/usercp${props.to}`}>
        <span><FaIcon name={`${props.icon} fa-fw`} /> <span className="link-title">{props.children}</span></span>
      </a>
    </li>
  )
}

const FakeNextNavSubLink = (props) => {
  return (
    <li className="nav-item">
      <a className="nav-link" role="menuitem" href={`/usercp${props.to}`}>
        <span className="child-spacer" /> <span className="link-title">{props.children}</span>
      </a>
    </li>
  )
}

const NextUsercpMenu = ({standalone}) => {
  const [openCategory, setOpenCategory] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setOpen(!open);
  }

  const onToggle = (category) => {
    if(openCategory == category)
      setOpenCategory(null);
    else
      setOpenCategory(category);
  }

  const NextNavLink = standalone ? FakeNextNavLink : RealNextNavLink;
  const NextNavSubLink = standalone ? FakeNextNavSubLink : RealNextNavSublink;

  return (
    <>
      <div className="bd-sidebar">
        <div className="bd-search align-items-center">
          <button onClick={handleToggle} aria-controls="bd-docs-nav" aria-expanded="false" aria-label="Toggle docs navigation" className="btn btn-link bd-search-docs-toggle d-md-none p-0 ml-auto text-secondary" type="button">
            <i className="fa fa-bars fa-2x"></i>
          </button>
        </div>

        <nav className={open ? "collapse navbar-collapse bd-links show" : "collapse navbar-collapse bd-links"} id="menuNav">
          <ul className="nav nav-pills nav-next nav-stacked flex-column" role="menu">
            <NextNavLink to="/" icon="home" exact>Home</NextNavLink>
            <NextNavLink to="/tickets" icon="ticket">Support-Tickets</NextNavLink>
            <NextNavLink icon="envelope-o" isActive={(_, { pathname }) => (pathname.match(/\/(email|mailboxes|aliases|mailbox_imports)/) && !pathname.match(/^\/email-adresse/))} to="/email">E-Mail</NextNavLink>
            <NextNavLink icon="globe" to="/domains">Domains</NextNavLink>
            <NextCategory category="webhosting" onToggle={() => onToggle('webhosting')} open={openCategory == 'webhosting'} icon="server" title="Webhosting">
              <NextNavSubLink to="/webhosting">Webhosting-Paket</NextNavSubLink>
              <NextNavSubLink to="/websites" isActive={(_, { pathname }) => (pathname.match(/^\/websites/) && !pathname.match(/\/websites\/statistics/))}>Webseiten</NextNavSubLink>
              <NextNavSubLink to="/wordpress">WordPress</NextNavSubLink>
              <NextNavSubLink to="/ssh_accounts">SSH</NextNavSubLink>
              <NextNavSubLink to="/ftp_accounts">FTP-Zugang</NextNavSubLink>
              <NextNavSubLink to="/websites/statistics">Statistiken</NextNavSubLink>
              <NextNavSubLink to="/softwares">Software-Installer</NextNavSubLink>
              <NextNavSubLink to="/memcached">Memcached</NextNavSubLink>
              <NextNavSubLink to="/php_version">PHP-Version</NextNavSubLink>
              <NextNavSubLink to="/php_error_log">PHP-Log</NextNavSubLink>
              <NextNavSubLink to="/phpmails">PHP-Mailversand</NextNavSubLink>
              <NextNavSubLink to="/webspace_backups">Backups</NextNavSubLink>
              <NextNavSubLink to="/cronjobs">Cronjobs</NextNavSubLink>
            </NextCategory>
            <NextCategory category="databases" onToggle={() => onToggle('databases')} open={openCategory == 'databases'}  icon="database" title="Datenbanken">
              <NextNavSubLink to="/databases">Datenbanken</NextNavSubLink>
              <NextNavSubLink to="/database_users">Datenbank-Benutzer</NextNavSubLink>
              <NextNavSubLink to="/phpmyadmin">phpMyAdmin</NextNavSubLink>
            </NextCategory>
            <NextCategory category="vms" onToggle={() => onToggle('vms')} open={openCategory == 'vms'} icon="cloud" title="Virtuelle Maschinen">
              <NextNavSubLink isActive={(_, { pathname }) => (pathname.match(/\/virtual_machines/) && !pathname.match(/\/virtual_machines\/snapshots/) && !pathname.match(/\/virtual_machines\/charges/))} to={`/virtual_machines`}>Virtuelle Maschinen</NextNavSubLink>
              <NextNavSubLink to="/virtual_machines/snapshots">Snapshots</NextNavSubLink>
              <NextNavSubLink to="/virtual_machines/charges">Abrechnung</NextNavSubLink>
            </NextCategory>
            <NextNavLink icon="cog" to="/settings">Einstellungen</NextNavLink>
          </ul>
        </nav>

      </div>
    </>
  )
}

const StandaloneUsercpMenu = () => (
  <NextUsercpMenu standalone />
)

export { NextUsercpMenu, UsercpMenu };
export default StandaloneUsercpMenu;
