import { FaIcon } from 'client/js/util/layout_utils';
import ItemErrors from '../item_errors';
import QuantityInput from '../components/quantity_input';

const DomainItem = (props) => {
  return <tr className={props.valid ? null : "error"}>
    <td>
      <p className="title">{props.name} {props.premium_domain ? <span className="badge badge-primary">Premium-Domain</span> : null}</p>
      {props.inclusive_domain ? 'Inklusiv-Domain' : (
        <>
          {props.description}
          <br/>
          Abrechnung 12 Monate im Voraus
        </>
      )}
      {props.tld == 'eu' ? <strong className="text-danger">.eu-Domains können nur mit Wohnsitz in einem EU-Mitgliedsstaat registriert werden.</strong> : null}
      {!props.inclusive_domain && props.whois_proxy_available &&
        <p className="whoisproxy-option custom-control custom-checkbox">
          <input className="custom-control-input" id={`option_whoisproxy_${props.id}`} name="option_whoisproxy" type="checkbox" checked={props.whois_proxy_enabled} disabled={!props.toggleWhoisProxy} onChange={(e) => (props.toggleWhoisProxy(props.id, e.target.checked))} />
          <label className="whoisproxy-toggle custom-control-label" htmlFor={`option_whoisproxy_${props.id}`}>
            WhoisProxy aktivieren (+ {format_fractional_price(props.whois_proxy_extra_charge)} p.a.)
          </label>
        </p>}
      <ItemErrors errors={props.errors} />
    </td>
    <td className="price">
      {props.changeQuantity && !props.inclusive_domain ? <QuantityInput min="1" id={props.id} value={props.quantity} onChange={(value) => props.changeQuantity(props.id, value)} /> : props.quantity} Jahre
    </td>
    <td className="price">
      0,00 €
    </td>
    {props.discounted ?
      <td className="price">
        <div className="old-price">statt {format_fractional_price(props.amount)}</div>
        nur {format_fractional_price(props.adjusted_total)}
        <br />
        <small>{format_fractional_price(props.price_per_month)}/Monat</small>
      </td> : <td className="price">
        {format_fractional_price(props.amount)}
        <br />
        <small>{format_fractional_price(props.price_per_month)}/Monat</small>
      </td>
    }
    {props.inclusive_domain ? <td className="price">-</td> :
    <td className="price">
      danach {format_fractional_price(props.renew_price)}
      <br />
      {i18n_c('every_month', 12)}
      <br />
      <small>{format_fractional_price(props.renew_price/12)}/Monat</small>
    </td>}
    <td className="pl-0">
      {props.removeItem && <a className="remove_from_cart" href={`/cart/remove_item?id=${props.id}`} onClick={(e) => {e.preventDefault() ; props.removeItem(props.id)}}><FaIcon name="trash fa-lg" /></a>}
    </td>
  </tr>
}

export default DomainItem;
