import { FaIcon } from 'client/js/util/layout_utils';
import { useEffect, useRef, useState } from 'react';
import {  NavLink, } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

const NextNavLink = (props) => {
  return (
    <li className="nav-item">
      <NavLink className="nav-link" role="menuitem" activeClassName="active" to={props.to} exact={props.exact} isActive={props.isActive}>
        <span><FaIcon name={`${props.icon} fa-fw`} /> <span className="link-title">{props.children}</span></span>
      </NavLink>
    </li>
  )
}

const NextNavSubLink = (props) => {
  return (
    <li className="nav-item">
      <NavLink className="nav-link" role="menuitem" activeClassName="active" to={props.to} exact={props.exact} isActive={props.isActive}>
        <span className="child-spacer" /> <span className="link-title">{props.children}</span>
      </NavLink>
    </li>
  )
}

const NextCategory = ({open, onToggle, category, icon, title, children}) => {
  const [submenuHeight, setSubmenuHeight] = useState(0);
  const submenuRef = useRef(null);
  const ariaId = `nav-${category}-submenu`;
  const ariaButtonId = `nav-${category}-button`;

  const handleClick = (e) => {
    e.preventDefault();
    onToggle();
  }

  useEffect(() => {
    if (submenuRef.current) {
      // Set the height when opening
      const height = submenuRef.current.scrollHeight;
      setSubmenuHeight(height);
    }
  });

  return (
    <>
      <li className="nav-item">
        <a
          className="nav-link"
          onClick={handleClick}
          data-toggle="collapse"
          href={`#${category}`}
          role="button"
          id={ariaButtonId}
          aria-expanded={open}
          aria-controls={ariaId}
          aria-haspopup="true"
          >
          <div className="d-flex justify-content-between align-items-center">
            <span><FaIcon name={`${icon} fa-fw`} /> <span className="link-title">{title}</span></span>

            <i className={open ? "fa fa-angle-up chevron" : "fa fa-angle-up rotate-180 chevron" }></i>
          </div>
        </a>
      </li>

      <>
        <CSSTransition
          in={open}
          timeout={200}
          classNames="slide"
          nodeRef={submenuRef}
        >
          <li className={open ? "nav-item nav-nested" : "nav-item nav-nested invisible"} aria-hidden={!open}>
            <ul
              ref={submenuRef}
              id={ariaId}
              role="menu"
              aria-labelledby={ariaButtonId}
              className="nav nav-stacked nav-pills flex-column"
              style={{
                marginTop: open ? 0 : -submenuHeight || -9999,
              }}
            >
              {children}
            </ul>
          </li>
        </CSSTransition>
      </>
    </>
  )
}

export { NextNavLink, NextNavSubLink, NextCategory };
