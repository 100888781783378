import Callout from 'client/js/util/callout';
import ExternalLink from 'client/js/util/external_link';
import { FormActions, GenericReactForm, StringInput, SubmitButton } from 'client/js/util/form_utils';
import { BigPrice, FaIcon } from 'client/js/util/layout_utils';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from "react-toastify";
import CrossSelling from './cross_selling';
import EmptyCart from './empty_cart';
import ItemErrors from './item_errors';
import DomainItem from './items/domain';
import DomainRenewalItem from './items/domain_renewal';
import SharedHostingItem from './items/shared_hosting_offer';
import Vserver from './items/vserver';
import { errorToToast } from '../util/rest_utils';
import QuantityInput from './components/quantity_input';
import ReactDOM from 'react-dom';

const UpsellingBanner = (props) => {
  let offer = props.shared_hosting_offer;
  if(!offer)
    return null;

  const upgrade = (e) => {
    e.preventDefault();
    props.upgrade(offer.offer_code);
  }

  return <Callout calloutClass="warning mb-4">
      <div className="row">
        <div className="col-md-9">
          {props.saved_amount ?
            <h5>Buche ein Webhosting-Paket und erhalte {i18n_c("saved_domains", props.saved_domains)} kostenlos!</h5> :
            <h5>Schnellere Webseite gefällig?</h5>}

          <p>Unsere Domains enthalten standardmäßig nur den kostenlosen Basis-Webspace. Unser {offer.name} hingegen enthält {i18n_c("shared_hosting_offer.inclusive_domains", offer.inclusive_domains)}. {props.saved_amount ? <span>Spare {format_fractional_price(props.saved_amount)} für {i18n_c("saved_domains", props.saved_domains)} im Warenkorb und erhalte</span> : <span>Erhalte</span>} schnelleren Webspace, {(offer.disk_space_gb - 10)} GB zusätzlichen Speicherplatz, tägliche Backups, SSH-Zugang und vieles mehr.</p>

          <ul>
            <li className="list-item">{(offer.disk_space_gb - 10)} GB mehr Speicherplatz</li>
            <li className="list-item">SSD-beschleunigte Webserver</li>
            <li className="list-item">garantierte Verfügbarkeit (99,9%)</li>
            <li className="list-item">PHP-Version wählbar</li>
            <li className="list-item">Zend Cache</li>
            <li className="list-item">unbegrenzt MySQL-Datenbanken ({offer.mysql_space_gb} GB Speicher)</li>
            <li className="list-item">tägliche Backups</li>
            <li className="list-item"><strong>14 Tage gesetzl. Widerrufsrecht!</strong></li>
          </ul>

          <p><small>Wir haben noch weitere <a href="/webhosting">Webhosting-Pakete</a>, auch mit 1 Monat Laufzeit!</small></p>
        </div>

        <div className="col-md-3 text-center">
          {offer.first_interval ?
            <div>
              <BigPrice price={offer.first_interval_price} old_price={offer.price} period='Monat' />
              für {i18n_c("month", offer.first_interval_months)}, <br />danach {format_fractional_price(offer.price)} pro Monat</div> :
            <BigPrice price={offer.price} period='Monat' />}

          <p><small>Laufzeit {i18n_c("month", offer.contract_period)}</small></p>

          <button className="btn btn-primary mb-1" onClick={upgrade}><FaIcon name="cart-plus" /> Upgrade buchen</button>
        </div>
      </div>
    </Callout>;
}

UpsellingBanner.propTypes = {
  upgrade: PropTypes.func.isRequired,

  shared_hosting_offer: PropTypes.shape({
    offer_code: PropTypes.string.isRequired,
    first_interval: PropTypes.bool.isRequired,
    first_interval_months: PropTypes.number,
    first_interval_price: PropTypes.number,
    price: PropTypes.number.isRequired,
    contract_period: PropTypes.number.isRequired
  })
};

const Adjustment = (props) => {
  return <tr className="success">
      <td colSpan="3">
        <div className="text-right">
          enthaltener Rabatt: <strong>{props.text}</strong>
        </div>
      </td>
      <td className="price">
        <strong className="text-success">{format_price(props.amount*100)}</strong>
      </td>
      <td></td>
      <td></td>
    </tr>
}

const CartItem = (props) => {
  if(props.orderable_type == 'domainrenewal')
    return <DomainRenewalItem {...props} />;

  if(props.orderable_type == 'domain')
    return <DomainItem {...props} />;

  if(props.orderable_type == 'shared_hosting_offer')
    return <SharedHostingItem {...props} />;

  if (props.orderable_type == 'vserver')
    return <Vserver {...props} />;

  return <tr className={props.valid ? null : "error"}>
    <td>
      <p className="title">{props.name}</p>
      {props.description}
      {props.orderable_type == 'migration_service' && <p className='mb-0'><ExternalLink href="/hilfe/umzugsservice">Mehr Informationen</ExternalLink></p>}
      <ItemErrors errors={props.errors} />
    </td>
    <td className="price">
      {props.changeQuantity ? <QuantityInput min="1" id={props.id} value={props.quantity} onChange={(value) => props.changeQuantity(props.id, value)} /> : props.quantity} {props.units}
    </td>
    <td className="price">-</td>
    {props.discounted ?
      <td className="price">
        <div className="old-price">statt {format_fractional_price(props.amount)}</div>
        {format_fractional_price(props.adjusted_total)}
      </td> : <td className="price">
        {format_fractional_price(props.amount)}
      </td>
    }
    <td className="price">-</td>
    <td className="pl-0">
      {props.removeItem && <a className="remove_from_cart" href={`/cart/remove_item?id=${props.id}`} onClick={(e) => {e.preventDefault() ; props.removeItem(props.id)}}><FaIcon name="trash fa-lg" /></a>}
    </td>
  </tr>
}

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {items: props.items, loading: props.loading};

    this.changeQuantity = this.changeQuantity.bind(this);
    this.toggleWhoisProxy = this.toggleWhoisProxy.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.reloadCart = this.reloadCart.bind(this);
    this.upgradeCart = this.upgradeCart.bind(this);
  }

  changeQuantity(item_id, value) {
    if(value.length == 0)
      return

    this.setState({loading: true});

    $.post("/cart.json", {quantity: {[item_id]: value} }).done((data) => {
      this.props.updateCartCb(data);
    }).always(() => {
      this.setState({loading: false});
    });
  }

  toggleWhoisProxy(item_id, value) {
    this.setState({loading: true});

    $.post("/cart/set_option.json", {id: item_id, state: value}).done((data) => {
      this.props.updateCartCb(data);
    }).always(() => {
      this.setState({loading: false});
    });
  }

  reloadCart() {
    this.setState({loading: true});

    $.get("/cart.json").done((data) => {
      this.props.updateCartCb(data);
    }).always(() => {
      this.setState({loading: false});
    });
  }

  upgradeCart(offer_code) {
    this.setState({loading: true});

    $.post("/cart/upgrade.json", {offer_code: offer_code}).done((data) => {
      this.props.updateCartCb(data);
      this.setState({loading: false});
    })
  }

  removeItem(item_id) {
    this.setState({loading: true});

    $.post("/cart/remove_item.json", {id: item_id }).done((data) => {
      this.props.updateCartCb(data);
      this.setState({loading: false});
    })
  }

  render() {
    if(this.props.items == null)
      return <div className="css-spinloader fullpage-spinner"></div>

    return <div id="cart_wrapper" className={this.state.loading ? 'reloading mb-5' : 'mb-5'}>
      {this.state.loading && <div className="css-spinloader fullpage-spinner"></div>}
      <form acceptCharset="UTF-8" action="/cart" className="form-large-inputs" id="cart" method="post">

      {this.props.recommendation.available &&
        <UpsellingBanner shared_hosting_offer={this.props.recommendation.shared_hosting_offer}
                       saved_amount={this.props.recommendation.saved_amount}
                       saved_domains={this.props.recommendation.saved_domains}
                       upgrade={this.upgradeCart} />}

      <table className="table table-striped table-cart">
        <thead>
          <tr>
            <th colSpan="2">Produkt</th>
            <th colSpan="2"><div className="text-center">Einmalige Beträge</div></th>
            <th>Folgerechnungen</th>
            <th></th>
          </tr>
          <tr>
            <th colSpan="2"></th>
            <th>Einrichtungsgebühr</th>
            <th>Zahlung heute</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.items.map((item) => (
            <>
              <CartItem
                key={item.id}
                toggleWhoisProxy={this.toggleWhoisProxy}
                removeItem={this.removeItem}
                changeQuantity={this.changeQuantity}
                {...item}
              />
              {item.adjustments.map((adjustment) => (
                <Adjustment key={adjustment.text} {...adjustment} />
              ))}
            </>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">
              <strong>Summe</strong>
            </td>
            <td className="price">
              <strong>{format_fractional_price(this.props.total)}</strong>
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      </form>

      <div className="btn-group">
        <button className="btn btn-update-cart" name="button" type="submit" onClick={(e) => {e.preventDefault() ; e.target.blur() ; this.reloadCart()}}><FaIcon name="repeat" /> aktualisieren</button>
        <a className="btn btn-secondary" href="#" onClick={this.props.start_redeem_coupon} id="redeem_coupon_button">Gutschein einlösen</a>
        <a className="btn btn-success" href="/domain-check"><FaIcon name="search" /> zum Domain-Check</a>
      </div>

      {this.props.all_items_valid ?
        <a href="/checkout" className="btn btn-primary btn-lg checkout pull-right" id="checkout_button">
          <FaIcon name="shopping-cart fa-lg" /> zur Kasse
        </a>
        :
        <a href="/checkout" className="btn btn-primary btn-lg checkout pull-right disabled" id="checkout_button" onClick={(e) => e.preventDefault()}>
          <FaIcon name="shopping-cart fa-lg" /> zur Kasse
        </a>}
    </div>;
  }
}

class RedeemCouponForm extends React.Component {
  constructor(props) {
    super(props);
    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action, original_event, data) {
    if(action == 'form:success') {
      this.props.coupon_redeemed(data);
    }
  }

  render() {
    return <Callout title="Gutschein einlösen">
        <GenericReactForm namespace="coupon" url="/cart/redeem_coupon" dispatch={this.dispatch}>
          <StringInput required name="code" label="Gutschein-Code" />

          <FormActions>
            <SubmitButton icon="plus" text="Gutschein hinzufügen" />
            <br />
            <br />
            <button onClick={this.props.abort_redeem_coupon} className="btn btn-secondary">abbrechen</button>
          </FormActions>
        </GenericReactForm>
        </Callout>;
  }
}

const CartItemCountPortal = ({ itemCount }) => {
  if (typeof document === 'undefined') return null;

  const target = document.getElementById('navCartItemCount');

  if (!target) {
    return null;
  }

  const hasBeenCleared = target?.getAttribute('data-cleared') === 'true';

  // Clear the contents of the portal target if it hasn't been cleared before
  if (!hasBeenCleared) {
    target.innerHTML = '';
    target.setAttribute('data-cleared', 'true');
  }

  return ReactDOM.createPortal(
    <>
      <i className="fa fa-shopping-cart fa-lg fa-fw"></i>
      {!!(itemCount && itemCount > 0) && <span className="badge badge-pill badge-danger">{itemCount}</span>}
    </>,
    target
  );
};

class CartContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {items: null, total: null, all_items_valid: null};

    // this.updateCartItems = this.updateCartItems.bind(this);
    this.updateCartCb = this.updateCartCb.bind(this);
    this.start_redeem_coupon = this.start_redeem_coupon.bind(this);
    this.coupon_redeemed = this.coupon_redeemed.bind(this);
    this.abort_redeem_coupon = this.abort_redeem_coupon.bind(this);
  }

  componentDidMount() {
    $.get('/cart.json').done((data) => {
      this.updateCartCb(data);
    }).fail(() => {
      this.setState({items: null});
    })
  }

  updateCartCb(data) {
    this.setState({items: data.items,
                   cross_selling: data.cross_selling,
                   all_items_valid: data.all_items_valid,
                   cart_total: data.total,
                   recommendation: data.recommendation});
  }

  start_redeem_coupon(e) {
    e.preventDefault();
    this.setState({redeem_coupon: true});
  }

  abort_redeem_coupon() {
    this.setState({redeem_coupon: false});
  }

  coupon_redeemed(data) {
    this.updateCartCb(data);
    this.setState({redeem_coupon: false});
  }

  add_item = (e, item) => {
    e.preventDefault();
    this.setState({loading: true});
    $.post(item.add_to_cart_url).done((data) => {
      this.setState({loading: false});
      this.updateCartCb(data);
    }).fail((xhr) => {
      this.setState({ loading: false });
      errorToToast(xhr, "beim Hinzufügen des Produkts zum Warenkorb");
    });
  }

  getItemCount() {
    return this.state.items ? this.state.items.length : 0;
  }

  render() {
    if(this.state.items && this.state.items.length == 0)
      return (
      <React.Fragment>
        <EmptyCart />
        <CrossSelling data={this.state.cross_selling} add_item={this.add_item} />
        <CartItemCountPortal itemCount={this.getItemCount()} />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      </React.Fragment>
    );

    if(this.state.redeem_coupon)
      return <RedeemCouponForm coupon_redeemed={this.coupon_redeemed} abort_redeem_coupon={this.abort_redeem_coupon} />;

      return <React.Fragment>
        <Cart total={this.state.cart_total}
                  loading={this.state.loading}
                  all_items_valid={this.state.all_items_valid}
                  items={this.state.items}
                  start_redeem_coupon={this.start_redeem_coupon}
                  recommendation={this.state.recommendation}
                  updateCartCb={this.updateCartCb} />
        <CrossSelling data={this.state.cross_selling} add_item={this.add_item} />
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <CartItemCountPortal itemCount={this.getItemCount()} />
      </React.Fragment>;
  }
}

export { Adjustment, CartItem, CartItemCountPortal };

export default CartContainer;
