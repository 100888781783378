import { FaIcon } from 'client/js/util/layout_utils';
import ItemErrors from '../item_errors';
import QuantityInput from '../components/quantity_input';

const DomainRenewalItem = (props) => {
  return <tr className={props.valid ? null : "error"}>
    <td>
      <p className="title">{props.name}</p>
      {props.description}
      <ItemErrors errors={props.errors} />
    </td>
    <td className="price">
      {props.changeQuantity ? <QuantityInput min="1" id={props.id} value={props.quantity} onChange={(value) => props.changeQuantity(props.id, value)} /> : props.quantity} Jahre
    </td>
    <td className="price">
      0,00 €
    </td>
    {props.discounted ?
      <td className="price">
        <div className="old-price">statt {format_fractional_price(props.amount)}</div>
        nur {format_fractional_price(props.adjusted_total)}
        <br />
        <small>{format_fractional_price(props.price_per_month)}/Monat</small>
      </td> : <td className="price">
        {format_fractional_price(props.amount)}
        <br />
        <small>{format_fractional_price(props.price_per_month)}/Monat</small>
      </td>
    }
    <td className="price">-</td>
    <td className="pl-0">
      {props.removeItem && <a className="remove_from_cart" href={`/cart/remove_item?id=${props.id}`} onClick={(e) => {e.preventDefault() ; props.removeItem(props.id)}}><FaIcon name="trash fa-lg" /></a>}
    </td>
  </tr>
}

export default DomainRenewalItem;
