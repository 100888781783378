import RecurringItem from './recurring_item';

const Description = (props) => {
  return <>
    <strong>Vertragsdetails</strong>
    <ul>
      {props.first_interval && <li>{props.offer_name}: {format_fractional_price(props.first_interval_price)} pro Monat für {i18n_c("month", props.first_interval_months)}</li>}
      {props.first_interval && <li>danach {format_fractional_price(props.price)} pro Monat</li>}
      {!props.first_interval && <li>{format_fractional_price(props.price)} pro Monat</li>}
      {props.free_setup ? <li>kostenlose Einrichtung</li> : <li>Einrichtungsgebühr {format_fractional_price(props.setup_price)} einmalig</li>}
      <li>Abrechnung {i18n_c("month", props.billing_cycle)} im Voraus</li>
      <li>Laufzeit {i18n_c("month", props.contract_period)}</li>
    </ul>
    <strong>Produktdetails</strong>
    <ul>
      <li>{props.memory_mb / 1024} GB RAM</li>
      <li>{props.vcores} vCores</li>
      <li>{props.ssd_storage_gb} GB SSD-Speicher</li>
      <li>{props.traffic_per_month_gb} GB Traffic/Monat</li>
    </ul>
  </>
}

const Vserver = (props) => (
 <RecurringItem {...props}>
    <Description {...props} />
  </RecurringItem>
)

export default Vserver;
