import { FaIcon } from './layout_utils';

const get_safe = (key, default_value) => {
  try {
    const v = localStorage.getItem("nav:" + key);

    if(v === null)
      return default_value;

    return JSON.parse(v);
  } catch(e) {
    return null;
  }
}

const set_safe = (key, value) => {
  try {
    return localStorage.setItem("nav:" + key, JSON.stringify(value));
  } catch(e) {
    return null;
  }
}

class CollapsibleMenu extends React.Component {
  state = {open: get_safe(`menu:${(this.props.menu_key || this.props.title).replace(/[^a-zA-Z0-9]/g, '')}`)}

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChange);
  }

  handleStorageChange = (e) => {
    if(e.key != "nav:menu:" + (this.props.menu_key || this.props.title).replace(/[^a-zA-Z0-9]/g, ''))
      return;

    this.setState({open: JSON.parse(e.newValue)});
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState((state) => {
      set_safe(`menu:${(this.props.menu_key || this.props.title).replace(/[^a-zA-Z0-9]/g, '')}`, !state.open);
      return {open: !state.open};
    });
  }

  render() {
    return <li>
        <a href="#" onClick={this.handleClick}>
          <h5 className={`section-head ${this.state.open ? 'px-2' : 'px-2 mb-3'}`}>
            {this.state.open ? <FaIcon name="angle-down fa-fw float-right" /> : <FaIcon name="angle-left fa-fw float-right" />}
            {this.props.title}
          </h5>
        </a>

        {this.state.open ? <ul className="nav nav-pills nav-stacked flex-column mb-3">{this.props.children}</ul> : null}
      </li>
  }
}

export { CollapsibleMenu, get_safe, set_safe };

